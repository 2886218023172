.wrapper {
   --search-border-radius: 92px;
   --search-height: 46px;
   --search-top-spacer: 9px;
   --search-button-width: 52px;

   width: 100%;
   height: var(--default-layout-header-height);
   box-shadow: 0px 1px 1px rgb(0 0 0 /12%);
   display: flex;
   justify-content: center;
}

.inner {
   height: 100%;
   width: var(--default-layout-width);
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.logo {
}

.search,
.search-result {
   width: 361px;
}

.search-result {
}

.search {
   position: relative;
   height: var(--search-height);
   padding-left: 16px;
   background-color: rgba(22, 24, 35, 0.06);
   border-radius: var(--search-border-radius);
   display: flex;
   border: 1.5px solid transparent;

   input {
      height: 100%;
      color: var(--black);
      caret-color: var(--primary);
      flex: 1;
      font-size: 1.6rem;
      background-color: transparent;
   }

   input:not(:placeholder-shown) ~ .search-btn {
      color: rgba(22, 24, 35, 0.75);
   }

   &::after {
      content: '';
      position: absolute;
      top: var(--search-top-spacer);
      right: var(--search-button-width);
      width: 1px;
      height: calc(var(--search-height) - var(--search-top-spacer) * 2);
      background-color: rgba(22, 24, 35, 0.12);
   }

   &:focus-within {
      border-color: rgba(22, 24, 35, 0.2);
   }
}
.search-title {
   padding: 5px 12px;
   font-size: 1.4rem;
   font-weight: 600;
   color: rgba(22, 24, 35, 0.5);
}

.clear,
.loading {
   position: absolute;
   right: calc(var(--search-button-width) + 16px);
   top: 50%;
   transform: translateY(-50%);
   color: rgba(22, 24, 35, 0.34);
}
.search-btn {
   width: var(--search-button-width);
   height: 100%;
   border-top-right-radius: var(--search-border-radius);
   border-bottom-right-radius: var(--search-border-radius);
   font-size: 1.8rem;
   color: rgba(22, 24, 35, 0.34);

   &:hover {
      cursor: pointer;
      background-color: rgba(22, 24, 35, 0.03);
   }

   &:active {
      background-color: rgba(22, 24, 35, 0.06);
   }
}

.more-btn {
   font-size: 2rem;
   margin-left: 28px;
   background-color: transparent;
}

.menu-items {
   width: 224px;
}
