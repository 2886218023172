.wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.container {
   width: var(--default-layout-width);
   min-height: 1000px;
   background-color: blueviolet;
   display: flex;
}

.content {
   flex: 1;
   min-height: 1000px;
   background-color: cadetblue;
}
